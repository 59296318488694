<template>
  <div>
    <b-row>
      <b-col md="6">        
        <!-- <p>URL: {{url}}</p>  -->
        <b-form-group>
          <label for="firstName"><strong>URL<small class="alert-text"> *</small></strong></label>
          <b-form-input
              type="text"
              id="tag"
              v-model="url"
              placeholder="Inserta el código secuencial de la placa"
              >
          </b-form-input>           
        </b-form-group>


        
        <b-form-group>
          <label for="firstName"><strong>Id de la placa<small class="alert-text"> *</small></strong></label>
          <b-form-input
              type="text"
              id="tag"
              v-model="tagId"
              placeholder="Inserta el código secuencial de la placa"
              >
          </b-form-input>           
        </b-form-group>

        <b-button @click="generateQR">
          Generar QR
        </b-button>
      </b-col>
      <b-col md="6">
        <strong>Código QR para enviar a imprimir:</strong>

        <div>
          TagId: {{tagId}}
        </div>

        <div>
          Tag Id Encriptado: {{tagIdEncrypted}}
        </div>
      
        <br>
        <p>{{url}}{{tagId}}</p> 
        <!-- <p>{{url}}{{tagIdEncrypted}}</p>  -->
        
         <div id="goqrcode"></div>
      </b-col>    
    </b-row>   
  </div>
</template>

<script>
import GoQrcode from "go-qrcode";
import md5 from 'md5'
export default {
    name: "QRGenerator",    
    data(){
      return {
        url: 'goguau.co/p/',        
        // url: 'goguau.co/public/pet/',        
        tagId: '',             
      }
    },
    computed: {
      tagIdEncrypted(){
        if(this.tagId == '') {
          return ''
        } else {
          return md5(this.tagId)
        }
      }
    },

    methods:{
      generateQR(){
       console.log('generar código: ', this.tagId)
       
       console.log(this.tagIdEncrypted)
       let domel = document.getElementById("goqrcode");
        var options= {
          // text: this.url+this.tagIdEncrypted,
          text: this.url+this.tagId,
          width: 180,
          height: 180,
          colorDark: "#000000",
          colorLight: "#ffffff",                
          correctLevel: GoQrcode.CorrectLevel.L, // L, M, Q, H
          version: 1
        }
        // Create new QRCode Object
        new GoQrcode(domel, options);
      }
    }
}
</script>

<style>


</style>